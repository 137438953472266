import React from "react";
import { BaseLayout, OuterContainer } from "../components/Layout";
import { ContactForm } from "../components/Common";
import { TbClock, TbClipboardList, TbMapPin, TbPhone } from "react-icons/tb";
import { StaticImage } from "gatsby-plugin-image";

const contact = () => {
	return (
		<BaseLayout page="contact" leftBox="faq" rightBox="about">
			<div className=" md:grid-cols-2 lg:container max-w-screen-2xl grid grid-cols-1 mx-auto">
				<div className="flex flex-col gap-5 p-5 m-5 text-center rounded shadow-md">
					<div className="flex flex-col gap-5">
						<TbClock className="text-blue-951 w-12 h-12 m-auto stroke-1" />
						<h2 className="h4 leading-none text-gray-500">
							BUSINESS HOURS
						</h2>
					</div>
					<div className="h-96 flex flex-col justify-center">
						<dl className=" mb-8">
							<dt className="md:leading-loose text-base leading-tight text-gray-500">
								Mon-Fri
							</dt>
							<dd>
								<div className="text-blue-951 text-2xl">
									8:00
									<span className="text-sm text-gray-500">
										{" "}
										AM -
									</span>{" "}
									5:00
									<span className="text-sm text-gray-500">
										{" "}
										PM
									</span>
								</div>
							</dd>
						</dl>
						<dl className=" mb-8">
							<dt className="md:leading-loose mt-2 text-base leading-tight text-gray-500">
								Saturdays
							</dt>
							<dd>
								<div class="text-2xl text-blue-951 ">
									By appointment only
								</div>
							</dd>
						</dl>
						<dl className=" mb-8">
							<dt className="md:leading-loose mt-2 text-base leading-tight text-gray-500">
								Sundays & Holidays
							</dt>
							<dd>
								<div class="text-2xl text-blue-951 ">
									Closed
								</div>
							</dd>
						</dl>
					</div>
				</div>
				<div className="flex flex-col gap-5 p-5 m-4 text-center rounded shadow-md">
					<div className="flex flex-col gap-5">
						<TbMapPin className="text-blue-951 w-12 h-12 m-auto stroke-1" />
						<h2 className="h4 leading-none text-gray-500">MAP</h2>
					</div>
					{/* <iframe
            className="h-96 border-0 rounded"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d154950.56284158243!2d1.1467672545435896!3d52.64006035512377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d0aad55133f513%3A0x684ca5e00e12452f!2sNorwich!5e0!3m2!1sen!2suk!4v1658799653372!5m2!1sen!2suk"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
          ></iframe> */}
					{/* <Image> */}
					<StaticImage
						src="../images/map-image/TtT_service_area.jpg"
						alt="turn-the-tides-service-area"
						className="h-96 border-0 rounded"
					/>
				</div>
				<div className="flex flex-col gap-5 p-5 m-4 text-center rounded shadow-md">
					<div className="flex flex-col gap-5">
						<TbPhone className="text-blue-951 w-12 h-12 m-auto stroke-1" />
						<h2 className="h4 leading-none text-gray-500">
							TELEPHONE
						</h2>
					</div>
					<p className="md:leading-loose text-base leading-tight text-gray-500">
						Call Caroline on for an initial chat, or complete our
						enquiry form.
					</p>
					<p className="text-blue-951 text-2xl">07796512781</p>
				</div>
				<div className="flex flex-col gap-5 p-5 m-4 text-center rounded shadow-md">
					<div className="flex flex-col gap-5">
						<TbClipboardList className="text-blue-951 w-12 h-12 m-auto stroke-1" />
						<h2 className="h4 leading-none text-gray-500">
							FREE ASSESSMENT
						</h2>
					</div>
					<p className="md:leading-loose text-base leading-tight text-gray-500">
						Contact us and we will offer a free assessment of how we
						can help you.
					</p>
					<p className="text-blue-951 text-2xl">
						No Strings Attached
					</p>
				</div>
			</div>

			<OuterContainer reversed>
				<ContactForm />
			</OuterContainer>
		</BaseLayout>
	);
};
export default contact;
